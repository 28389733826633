import { Link, useMatch, useResolvedPath } from "react-router-dom"

export default function Navbar() {
  return (
    <nav className="nav">
      <ul className="nav">
        <CustomLink to="/">Welcome</CustomLink>
        <CustomLink to="/Program">Program</CustomLink>
        <CustomLink to="/Teacher">Guru</CustomLink>
        <CustomLink to="/Student">Disciple</CustomLink>
        <CustomLink to="/Orchestra">Orchestra</CustomLink>
        {/* <CustomLink to="/LiveStream">LiveStream</CustomLink> */}
      </ul>
    </nav>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
