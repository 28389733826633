import React from "react";
import "./ImageStyles.css";
import imageStudent from "./AK-Welcome.jpeg";

//const guruNameText = <p className="line-text-bold">Adithi Kashyap</p>;
const longTextBio1 = "Adithi Kashyap is a rising senior at the Downingtown STEM Academy and has been learning Bharathanatyam under Guru Smt. Sangeetha Balanchandar from the young age of 6.";
const longTextBio2 = "Being a first-generation Indian American, Adithi finds Indian classical dance as a bridge, a connection to the deep rooted Indian culture. She says, dance helps her to balance the complexities of every day grind, it is an escape from reality, a stress reliever, an opportunity to express herself."
const longTextBio3 = "Adithi has showcased Bharathanatyam dance at various the local community organizations such as Kannada Koota, Exton Senior Center, Council of Indian Organization to name a few. Beyond her devotion to dance. Adithi is a multifaceted individual, Adithi has been swimming for PAY for last 10 years and is a state level qualifier with distric recognition and also represents Downingtown East High School swim team. She is showcasing her talents not only in the pool as an avid swimmer but also on the canvas as a skilled artist.";
//const longTextBio3 = "Academically, her interests gravitate toward medicine, and hopes to pursue a career in healthcare. While looking towards the future, Adithi aspires to continue her passion for dance at the collegiate level by joining a dedicated dance team.";
const longTextBio4 = "For the past eleven years, Adithi is attending Chinmaya Mission, which has rooted her to the Indian culture, teachings and traditions. True to the Chinmaya mission statement of Contributing more to the society than one consumes, she has volunteered to help at soup kitchens, with collecting food donations, and to raise money for charity organizations, her motto being caring for anyone in need."
const longTextBio5 = "To give back to the community, she has voluntered at safe harbor of chester county, contibuted time and effort to make simple things like sandwiches or creating key chains or display board to raise money etc. Throughout years, she has actively participated in fundraisers such as AIM For Seva and Sankara Eye Foundation."
const longTextBio6 = "At school, Adithi actively participates in clubs and in Student council. Thru the HOSA club, qualified for both the state and national levels and keenly interested in collaborating with like minded peers to discuss prevalent issues and possible solutions. Adithi also helps plan events and fundraisers to benefit the class of ‘25 along with local businesses and organizations such as Cure4Cam (childhood cancer research) and the DVCCCPA (domestic violence center). As a hobby, she loves taking still photographs, she enjoys her role as a class historian, taking photographs and preserving many memories made throughout high school."
const longTextBio7 = "Adithi volunteers for an 501(c)(3) non-profit organization called AIM for Seva. Their goal is to empower students through value-based education in rural India through a network of Chatralayas (free student homes) and schools. For years, Adithi has been helping to plan and serve in many of AIM for Seva fundraisers to help underprivileged children. In December of 2022, she was accepted into the Global Youth Leadership Program and traveled to India to teach school subjects to kids in low-income areas. "
const longTextBio8 = "While looking towards the future, Adithi aspires to continue her passion for dance at the collegiate level by joining a dedicated dance team and continue seva by contributing to the community.";

export default function Student() {
  return (
    <div>
      <img src={imageStudent} alt="guru welcome" />
      <p className="line-text">{longTextBio1}</p>
      <p className="line-text">{longTextBio2}</p>
      <p className="line-text">{longTextBio3}</p>
      <p className="line-text">{longTextBio4}</p>
      <p className="line-text">{longTextBio5}</p>
      <p className="line-text">{longTextBio6}</p>
      <p className="line-text">{longTextBio7}</p>
      <p className="line-text">{longTextBio8}</p>
    </div>
  );
}
