import { createClient } from "@supabase/supabase-js";


//const res = await fetch("https://ectmbgepbmkohxhftlbm.supabase.co/rest/v1/rsvp", {
//        headers: {
//            apikey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVjdG1iZ2VwYm1rb2h4aGZ0bGJtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTMwMjMzNDgsImV4cCI6MjAyODU5OTM0OH0.F9pCBOwZoIGoZ4FOwftF7ByEhTXGme8RwFYcnbDd14w",
//            authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVjdG1iZ2VwYm1rb2h4aGZ0bGJtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTMwMjMzNDgsImV4cCI6MjAyODU5OTM0OH0.F9pCBOwZoIGoZ4FOwftF7ByEhTXGme8RwFYcnbDd14w",
//        }
//    });

const REACT_APP_SUPABASE_URL = "https://ectmbgepbmkohxhftlbm.supabase.co"
const REACT_APP_SUPABASE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVjdG1iZ2VwYm1rb2h4aGZ0bGJtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTMwMjMzNDgsImV4cCI6MjAyODU5OTM0OH0.F9pCBOwZoIGoZ4FOwftF7ByEhTXGme8RwFYcnbDd14w"
const supabase = createClient(REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY);

export default supabase;
