import Navbar from "./Navbar"
import Home from "./pages/Home"
import Program from "./pages/Program"
import Teacher from "./pages/Teacher"
import Student from "./pages/Student"
import Orchestra from "./pages/Orchestra"
import LiveStream from "./pages/LiveStream"
import { Route, Routes } from "react-router-dom"
import { useState } from "react";
import supabase from './supabase';
import "./App.css";

function App() {
  const [showForm, setShowForm] = useState(false);
  return (
    <>
      <Header showForm={showForm} setShowForm={setShowForm} />
      {showForm ? <NewFactForm setShowForm={setShowForm} /> : null}

      <Navbar />

      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Program" element={<Program />} />
          <Route path="/Teacher" element={<Teacher />} />
          <Route path="/Student" element={<Student />} />
          <Route path="/Orchestra" element={<Orchestra />} />
          <Route path="/LiveStream" element={<LiveStream />} />
        </Routes>
      </div>
    </>
  )
}

function Header({ showForm, setShowForm }) {
  const appTitle = "Adithi Kashyap Arangetram";
  return <header className="header">
    <h1 className="welcome">{appTitle}</h1>
    {/* <button className="colorBtn" onClick={() => setShowForm((show) => !show)}>{showForm ? "Close" : "RSVP"} </button> */}
  </header>
}

function NewFactForm({ setShowForm }) {
  const [fullName, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailAddress, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [howMany, setHowMany] = useState("");
  const [howManyError, setHowManyError] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState("");

  const fullNameLength = fullName.length;

  function IsValidRsvp(fullName, emailAddress, howMany) {
    let nameValidationError = false;
    let emailValidationError = false;
    let howManyValidationError = false;

    if (!fullName || fullNameLength <= 0) {
      setNameError("Full Name is required");
      nameValidationError = true;
    }

    if (fullNameLength > 100) {
      setNameError("Your Name is too long, cannot be more than 100 characters");
      nameValidationError = true;
    }

    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailAddress || reg.test(emailAddress) === false) {
      setEmailError("Please enter valid Email");
      emailValidationError = true;
    }

    if (!howMany) {
      setHowManyError("Please enter how many will attend");
      howManyValidationError = true;
    }

    if (isNaN(howMany)) {
      setHowManyError("Please enter (numbers only) how many will attend");
      howManyValidationError = true;
    }

    if (emailValidationError || nameValidationError || howManyValidationError) {
      return false;
    }

    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setThankYouMessage("");
    // check if valid data provided
    if (IsValidRsvp(fullName, emailAddress, howMany)) {
      // insert rsvp to supabase
      setIsUploading(true);

      // reset the error display fields
      setNameError("");
      setEmailError("");
      setHowManyError("");

      const { data: newRsvp, insertError } = await supabase.from("rsvp").insert([{ fullName, emailAddress, howMany }]).select();
      var thankYouMessage = "Thank you " + fullName + ", will see you on 29th June!!";
      setThankYouMessage(thankYouMessage);
      if (insertError != null) {
        console.log("Oh no...something went wrong while saving the rsvp. : " + insertError);
      }
      else {
        //setIsUploading(false);        
        console.log(newRsvp);
        // reset the input fields
        setName("");
        setEmail("");
        setHowMany("");
      }



      // close the form
      //setShowForm(false);
    }
  }

  return <form className="fact-form" onSubmit={handleSubmit}>
    <input type="text" placeholder="Full Name..." value={fullName} onChange={(e) => setName(e.target.value)} disabled={isUploading} />
    <span className="text-red">{nameError}</span>
    <input type="text" placeholder="your.email@domain.com" value={emailAddress} onChange={(e) => setEmail(e.target.value)} disabled={isUploading} />
    <span className="text-red">{emailError}</span>
    <input type="text" placeholder="how many will attend? " value={howMany} onChange={(e) => setHowMany(e.target.value)} disabled={isUploading} />
    <span className="text-red">{howManyError}</span>
    <button name="btnSave" className="colorBtn" disabled={isUploading} >Save</button>
    <span className="text-red">{thankYouMessage}</span>
  </form>
}

export default App