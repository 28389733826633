import React from "react";
import "./ImageStyles.css";
import imageGuru from "./AK-Sangeetha.jpeg";

const guruNameText = <p className="line-text-bold">Guru, Nattuvangam & Choreography: Smt. Sangeetha Balachandar</p>;
const longTextBio1 = "Sangeetha Balachandar, a trained Bharathanatyam dancer/teacher in the traditional Pandanallur Style, started her dance journey at the age of 6 from “Saahithya Shiromani” Guru Smt.Prabha Nagarajan and Guru Smt. Radha Srinivasan, Chennai, India.  She has also had carnatic vocal training under the guidance of Guru.Mudikondan Krishnamoorthy & her own mother Smt.Umaparvathy Rajasekar, a trained Classical Vocalist from Music College in Chennai,India. Completing her dance arangetram at the age of 13 , Sangeetha has given several dance performances throughout India & USA at prestigious venues like the “Natyanjali” dance festival in Chidambaram, India. In 1997 she had the honor to perform at the renowned dance festival of Tanjore “Ulaga Thamizh Manadu” organized by the Chief Minister of Tamil Nadu -“Late Jayalalitha” and also at the “Centenary Celebrations of Kanchi Paramacharya at Kanchipuram”, India. Apart from this she has also given performances in various sabhas like the Music Academy and Vani Mahal in India.";
const longTextBio2 = "After coming to the USA she has started teaching dance since 2001. Sangeetha was the lead teacher in Bharatanatyam at the “School of Indian Traditional Arts (SITA) in NJ & has performed at the Bridgewater Temple NJ, Delaware Mahalakshmi Temple, Penn's Landing Celebrations in PA and for the Om Shakthi Peedam`s Temple`s Celebrations. After relocating to Pennsylvania in 2007, Sangeetha and her husband Balachandar, a renowned flutist, established Natyaswara - An Indian arts school in Chester Springs which caters to teaching Bharatanatyam dance & Carnatic Flute . Natyaswara aims at propagating ancient art forms of India to students in the community and  to make every student a beautiful artist by bringing out their best. Students are taught life lessons of developing a strong work ethic and commitment to learning which helps them balance their mental and physical well-being through the medium of arts.";
const longTextBio3 = <p className="line-text">Natyaswara students  perform regularly at various venues, including the FESTIVAL OF INDIA celebrations at Penn's Landing in Philadelphia, HOLI Celebrations,YMCA & Henderson High School Diwali Celebrations in the presence of senator Robert Casey, SRUTI`s Thyagaraja Aradhana, Annamayya Rasa Tarangini, Tagdv(Tamil Sangam) in the PA, Sai Temple-Malvern, Iskcon Philadelphia, Navarasam Dance Festival at Sri Varadha Venkateswara Temple,  Hari Om Mandir in Downingtown and the Delaware Mahalakshmi Temple. The senior students of Natyaswara had the privilege to participate at the “Cleveland Thyagaraja Aradhana Festival'' in 2013, 2014 & 2019. Natyaswara students were invited to perform at the prestigious “Pottstown Memorial Hospital`s Awards Ceremony” and have also performed to help raise funds for Sankara Eye Foundation(SEF) , Om Shakthi Peedam`s Celebrating Life Events & Chennai Flood Relief hosted by Delaware Hindu Temple & Tagdv(Tamil Sangam),Exton Senior Living in 2015. The students were represented at the legendary MS Subbulakshmi`s Centenary Celebrations in December 2016. </p>;

export default function Teacher() {
  return (
    <div>
      {guruNameText}
      <img src={imageGuru} alt="guru welcome" />
      <p className="line-text">{longTextBio1}</p>
      <p className="line-text">{longTextBio2}</p>
      {longTextBio3}
    </div>
  );
}