//agenda
export default function Program() {
  return (
    <div>
      <p className="line-text-bold">Date: Saturday, June 29th,2024</p>
      <p className="line-text">______________________________________________</p>
      <p className="line-text">2:30 PM to 3:30 PM - RECEPTION AND REFRESHMENTS</p>
      <p className="line-text">3:15 PM - SEATING</p>
      <p className="line-text">3:30 PM - PROGRAM BEGINS</p>
      <p className="line-text">6:45 PM - DINNER</p>
      <p className="line-text">______________________________________________</p>
      <p className="line-text-bold">Address: Upper Merion Middle School</p>
      <p className="line-text-bold">450 Keebler Rd, King of Prussia, PA 19406</p>
      <div className="google-map-code">
        <iframe
          title="school-map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12207.705954365514!2d-75.3820417!3d40.0993519!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c695c98c8b6ecb%3A0x39bf832b93df7e5a!2sUpper%20Merion%20Area%20Middle%20School!5e0!3m2!1sen!2sus!4v1714333145532!5m2!1sen!2sus"
          width="500"
          height="300"
          frameborder="0"
          style={{ border: 0 }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        />
      </div>
    </div>
  );
}