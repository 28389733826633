import React from "react";
import "./ImageStyles.css";
//import imageBack from "./AK_Invite_Back.jpg";
//import imageFront from "./AK_Invite_Front.jpg";
import imageWelcome from "./brochure/1Welcome.jpg";
import imageExpressions from "./brochure/2Expressions.jpg";
import imageTheGuru from "./brochure/3TheGuru.jpg";
import imageTheShishya from "./brochure/4TheShishya.jpg";
import imagePrayers from "./brochure/5Prayers.jpg";
import imageBhoShambo from "./brochure/6BhoShambo.jpg";
import imageGallu from "./brochure/7Gallu.jpg";
import imageOrchestra from "./brochure/8Orchestra.jpg";
//import ImageSlider from "./ImageSlider";

// const Home = () => {
//   const slides = [
//     { url: imageWelcome, title: "Welcome" },
//     { url: imageExpressions, title: "Expressions" },
//     { url: imageTheGuru, title: "The Guru" },
//     { url: imageTheShishya, title: "The Shishya" },
//     { url: imagePrayers, title: "Prayers" },
//     { url: imageBhoShambo, title: "Bho Shambo" },
//     { url: imageGallu, title: "Ghallu Galenutha" },
//     { url: imageOrchestra, title: "Orchestra" },
//   ];
//   return (

//     <div className="home">
//       <div className="image-container">
//         <ImageSlider slides={slides} />
//       </div>
//     </div>

//   );
// };

// export default Home;

// export default function Home() {
//   return (
//     <div className="home">
//       <div className="image-container">
//         <img
//           src={imageFront} alt="ak dance Front"
//         />
//         <br></br>
//         <img
//           src={imageBack} alt="ak dance Back"
//         />
//       </div>
//     </div>
//   );
// }
export default function Home() {
  return (
    <div className="home">
      <div className="image-container">
        <img src={imageWelcome} alt="ak dance Welcome" />
        <br></br>
        <img src={imageExpressions} alt="ak dance Expressions" />
        <br></br>
        <img src={imageTheGuru} alt="The Guru" />
        <br></br>
        <img src={imageTheShishya} alt="The Shishya" />
        <br></br>
        <img src={imagePrayers} alt="Prayers" />
        <br></br>
        <img src={imageBhoShambo} alt="Bho Shambo" />
        <br></br>
        <img src={imageGallu} alt="Ghallu Galenutha" />
        <br></br>
        <img src={imageOrchestra} alt="Orchestra" />
      </div>
    </div>
  );
}
