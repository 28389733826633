
export default function Orchestra() {
  return (
    <div className="container">
      <p className="line-text">Nattuvangam<b>: Smt. Sangeetha Balachandar</b></p>
      <p className="line-text">...............................................................</p>
      <p className="line-text">Vocal <b>: Sri. Vignesh Ravichandran</b></p>
      <p className="line-text">...............................................................</p>
      <p className="line-text">Mridangam<b>: Sri. Murali Balachandran</b></p>
      <p className="line-text">...............................................................</p>
      <p className="line-text">Violin<b>: Kum. Swetha Narasimhan</b></p>
      <p className="line-text">...............................................................</p>
      <p className="line-text">Flute<b>: Sri. Balachandar Krishnaraj</b></p>
      <p className="line-text">...............................................................</p>
    </div>);
}
